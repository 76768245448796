import type { InputProps } from '@mui/material';
import { useCallback, useState } from 'react';

type OnBlurHandler = Exclude<InputProps['onBlur'], undefined>;
type OnChangeHandler = Exclude<InputProps['onChange'], undefined>;

interface Params {
  validator?: (value: string) => boolean;
}

export const useValueInput = ({ validator }: Params = {}): {
  error: boolean;
  value: string;
  onBlur: OnBlurHandler;
  onChange: OnChangeHandler;
} => {
  const [error, setError] = useState(false);
  const [value, setValue] = useState('');

  const onChange = useCallback<OnChangeHandler>(
    (event) => {
      setError(false);
      setValue(event.target.value);
    },
    [setValue],
  );
  const onBlur = useCallback<OnBlurHandler>(
    (event) => {
      const hasError = validator
        ? validator(event.target.value)
        : !event.target.value.trim();

      setError(hasError);
    },
    [setError, validator],
  );

  return {
    error,
    value,
    onBlur,
    onChange,
  };
};
