import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useAppDispatch } from '@/shared/model';
import { submitSnapchatAdsReportingIntegration } from '../../../model/actions';
import { BROADCAST_CHANNEL_NAME } from './constants';

interface SnapchatAuthCodeState {
  processing: boolean;
  openDataAccessDialog: () => void;
}

export const useSnapchatAuthCode = (): SnapchatAuthCodeState => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [processing, setProcessing] = useState(false);

  return {
    processing,
    openDataAccessDialog: () => {
      const clientId = import.meta.env.VITE_SNAPCHAT_ADS_REPORTING_CLIENT_ID;
      const redirectURI = window.location.href;
      const url = `https://accounts.snapchat.com/login/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectURI}&scope=snapchat-marketing-api`;
      const dataAccessWindow = window.open(url, '_blank');
      const broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
      const messageHandler = (event: MessageEvent<{ code: string }>) => {
        const { code } = event.data;

        dataAccessWindow?.close();
        broadcastChannel.removeEventListener('message', messageHandler);

        setProcessing(true);
        dispatch(
          submitSnapchatAdsReportingIntegration({
            clientId,
            redirectURI,
            code,
          }),
        )
          .catch(() => {
            enqueueSnackbar('Unable to add account. Please, try again.', {
              variant: 'error',
              autoHideDuration: 4000,
            });
          })
          .finally(() => {
            setProcessing(false);
          });
      };

      broadcastChannel.addEventListener('message', messageHandler);
    },
  };
};
