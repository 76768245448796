import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import {
  deleteIntegration,
  GOOGLE_PLAY_CONSOLE_REPORTING_INTEGRATION_ID,
  IntegrationActionsMenu,
  selectIntegrations,
  selectIsLoading,
} from '@/entities/datasources';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Button, Loader } from '@/shared/ui';
import type { GooglePlayConsoleReportingIntegration } from '../../model/types';
import { AddRecordRow } from './ui/AddRecordRow/AddRecordRow';

export function GooglePlayConsoleDatasource() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const integrationsLoading = useAppSelector(selectIsLoading);
  const integrations = useAppSelector(selectIntegrations);
  const googlePlayConsoleReportingIntegrations = useMemo(() => {
    return integrations.filter((integration) => {
      return (
        integration.sourceIntegrationId ===
        GOOGLE_PLAY_CONSOLE_REPORTING_INTEGRATION_ID
      );
    }) as GooglePlayConsoleReportingIntegration[];
  }, [integrations]);
  const [createEditorOpened, setCreateEditorOpened] = useState(false);

  const handleOpenCreateEditor = () => {
    setCreateEditorOpened(true);
  };
  const handleCloseCreateEditor = () => {
    setCreateEditorOpened(false);
  };
  const handleDeleteIntegration = (id: string) => () => {
    return dispatch(deleteIntegration(id))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Integration successfully deleted.', {
          variant: 'success',
          autoHideDuration: 2000,
        });
      })
      .catch(() => {
        enqueueSnackbar('Unable to delete integration. Please, try again.', {
          variant: 'error',
          autoHideDuration: 4000,
        });
      });
  };
  return (
    <Box
      sx={{
        py: 3,
        px: 5,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Cloud Storage URI</TableCell>
            <TableCell>Google Play Service Credentials (JSON)</TableCell>
            <TableCell sx={{ width: 170 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {googlePlayConsoleReportingIntegrations.length === 0 &&
            !createEditorOpened && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No accounts added
                </TableCell>
              </TableRow>
            )}
          {googlePlayConsoleReportingIntegrations.map((integration) => {
            return (
              <TableRow key={integration.id} sx={{ position: 'relative' }}>
                <TableCell>{integration.integrationConfig.name}</TableCell>
                <TableCell>
                  {integration.integrationConfig.cloudStorageURI}
                </TableCell>
                <TableCell sx={{ userSelect: 'none' }}>***</TableCell>
                <TableCell>
                  <IntegrationActionsMenu
                    integrationName={integration.integrationConfig.name}
                    onDelete={handleDeleteIntegration(integration.id)}
                  />
                </TableCell>
              </TableRow>
            );
          })}
          {createEditorOpened && (
            <AddRecordRow onClose={handleCloseCreateEditor} />
          )}
        </TableBody>
      </Table>
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        disabled={createEditorOpened}
        onClick={handleOpenCreateEditor}
      >
        Add record
      </Button>
      <Loader active={integrationsLoading} />
    </Box>
  );
}
