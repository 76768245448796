import { useEffect, useState } from 'react';
import { DatasourcesFilters } from '@/features/DatasourcesFilters';
import { setupIntegrations, type DatasourceType } from '@/entities/datasources';
import { useAppDispatch } from '@/shared/model';
import { Marketplace } from '../Marketplace/Marketplace';

export function DatasourcesMarketplace() {
  const dispatch = useAppDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [datasourceTypes, setDatasourceTypes] = useState<DatasourceType[]>([]);

  useEffect(() => {
    dispatch(setupIntegrations());
  }, [dispatch]);

  return (
    <>
      <DatasourcesFilters
        datasourceTypes={datasourceTypes}
        searchQuery={searchQuery}
        onDatasourceTypeChange={setDatasourceTypes}
        onSearchQueryChange={setSearchQuery}
      />
      <Marketplace
        datasourceTypes={datasourceTypes}
        searchQuery={searchQuery}
      />
    </>
  );
}
