import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import type { InputProps } from '@mui/material';
import { IconButton, OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

const Input = styled(OutlinedInput)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    fontSize: '14px',
  },
}));
const VisibilityButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export function MaskedInput(props: InputProps) {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((oldValue) => !oldValue);
  };

  return (
    <Input
      {...props}
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <VisibilityButton size="small" onClick={toggleShowPassword}>
          {showPassword ? (
            <VisibilityOutlinedIcon />
          ) : (
            <VisibilityOffOutlinedIcon />
          )}
        </VisibilityButton>
      }
    />
  );
}
