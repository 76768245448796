import { baseApi, getErrorMessage } from '@/shared/api';
import { mapIntegration } from '../libs/mapIntegration';
import type { Integration } from '../model/types';
import type { IntegrationDTO } from './types';

export const getIntegrations = async (): Promise<Integration[]> => {
  const { data, error } = await baseApi.functions.invoke<{
    integrations: IntegrationDTO[];
  }>('getOrganizationIntegrations');

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return data.integrations.map(mapIntegration);
};

export const deleteIntegration = async (id: string) => {
  const { error } = await baseApi.functions.invoke(
    'deleteOrganizationIntegration',
    {
      body: {
        integrationId: id,
      },
    },
  );

  if (error) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }
};
