import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import {
  GOOGLE_ANALYTICS_REPORTING_INTEGRATION_ID,
  Input,
  selectIntegrations,
  useValueInput,
} from '@/entities/datasources';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Loader } from '@/shared/ui';
import { submitGoogleAnalyticsReportingIntegration } from '../../../../model/actions';
import type { GoogleAnalyticsReportingIntegration } from '../../../../model/types';

interface Props {
  onClose: () => void;
}

export function AddRecordRow({ onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [processing, setProcessing] = useState(false);
  const integrations = useAppSelector(selectIntegrations);
  const bucketInputProps = useValueInput();
  const subscriptionIdInputProps = useValueInput();
  const hasExistingRecord = useMemo(() => {
    return integrations.some((integration) => {
      if (
        integration.sourceIntegrationId !==
        GOOGLE_ANALYTICS_REPORTING_INTEGRATION_ID
      ) {
        return false;
      }

      const config =
        integration.integrationConfig as GoogleAnalyticsReportingIntegration['integrationConfig'];

      return (
        config.bucket === bucketInputProps.value &&
        config.subscriptionId === subscriptionIdInputProps.value
      );
    });
  }, [integrations, bucketInputProps.value, subscriptionIdInputProps.value]);
  const hasInputsError =
    bucketInputProps.error || subscriptionIdInputProps.error;
  const hasError = hasInputsError || hasExistingRecord;

  const handleCreate = () => {
    if (hasError) {
      return;
    }

    setProcessing(true);
    dispatch(
      submitGoogleAnalyticsReportingIntegration({
        bucket: bucketInputProps.value,
        subscriptionId: subscriptionIdInputProps.value,
      }),
    )
      .then(onClose)
      .catch(() => {
        enqueueSnackbar('Unable to add record. Please, try again.', {
          variant: 'error',
          autoHideDuration: 4000,
        });
        setProcessing(false);
      });
  };

  return (
    <TableRow sx={{ position: 'relative' }}>
      <TableCell>
        <Input {...bucketInputProps} />
      </TableCell>
      <TableCell>
        <Input {...subscriptionIdInputProps} />
      </TableCell>
      <TableCell sx={{ px: 0, py: 0.5 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <IconButton
              sx={{ color: (theme) => theme.palette.success.main }}
              size="small"
              disabled={hasError}
              onClick={handleCreate}
            >
              <CheckIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon fontSize="small" color="error" />
            </IconButton>
          </Box>
          {hasInputsError && (
            <Typography color="error" variant="caption">
              Please, fill values in all fields
            </Typography>
          )}
          {hasExistingRecord && (
            <Typography color="error" variant="caption">
              Record already exists
            </Typography>
          )}
        </Box>
        <Loader active={processing} />
      </TableCell>
    </TableRow>
  );
}
