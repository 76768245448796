import type { IntegrationDTO } from '../api/types';
import type { Integration } from '../model/types';

export const mapIntegration = <T>(dto: IntegrationDTO<T>): Integration<T> => {
  return {
    id: dto.id,
    sourceIntegrationId:
      dto.sourceIntegrationId as Integration['sourceIntegrationId'],
    integrationConfig: dto.integrationConfig,
    updatedAt: dto.updatedAt,
    updating: false,
  };
};
