import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import type { ChangeEventHandler } from 'react';
import { useState } from 'react';
import { Analytics } from '@/shared/lib';
import { useAppSelector } from '@/shared/model';
import { Loader, SomethingWrongPanel } from '@/shared/ui';
import { selectIsFailed, selectIsLoading } from '../../model/slice';
import { NoViews } from '../NoViews/NoViews';
import { useSavedViewsContext } from '../SavedViewsContext/SavedViewsContext';
import { ViewsList } from '../ViewsList/ViewsList';

export function Views() {
  const isError = useAppSelector(selectIsFailed);
  const isLoading = useAppSelector(selectIsLoading);
  const { views } = useSavedViewsContext();
  const [query, setQuery] = useState('');
  const filteredViews = views.filter((view) => {
    return view.savedViewName.toLowerCase().includes(query.toLowerCase());
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setQuery(event.target.value);
    Analytics.sendFeatureUsage('saved_views', 'search_views', {
      page: window.location.pathname,
    });
  };
  let content = <ViewsList views={filteredViews} />;

  if (isError) {
    content = <SomethingWrongPanel />;
  } else if (!isLoading && views.length === 0) {
    content = <NoViews />;
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        position: 'relative',
      }}
    >
      <Typography
        component="h6"
        sx={{
          fontSize: '0.875rem',
          lineHeight: 1.43,
          fontWeight: 500,
          textTransform: 'uppercase',
        }}
      >
        Views folder
      </Typography>
      {!isError && (
        <OutlinedInput
          type="search"
          size="small"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          placeholder="Search for a view"
          fullWidth
          value={query}
          onChange={handleChange}
        />
      )}
      {content}
      <Loader active={isLoading} />
    </Box>
  );
}
