import type { ButtonProps } from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useState } from 'react';
import { Button, Loader } from '../ui';

interface UseConfirmationDialogParams {
  title: string;
  description: string;
  acceptButtonText: string;
  acceptButtonColor: ButtonProps['color'];
  onAccept: () => Promise<void>;
  onReject?: () => void;
}

export const useConfirmationDialog = ({
  title,
  description,
  acceptButtonText,
  acceptButtonColor,
  onAccept,
  onReject,
}: UseConfirmationDialogParams) => {
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setProcessing(false);
  };
  const handleAccept = () => {
    setProcessing(true);
    onAccept().then(() => {
      handleClose();
    });
  };
  const handleReject = () => {
    onReject?.();
    handleClose();
  };

  return {
    openDialog: () => {
      setOpen(true);
    },
    dialogElememnt: (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>{description}</DialogContent>
        <DialogActions>
          <Button
            sx={{ p: 1 }}
            color={acceptButtonColor}
            onClick={handleAccept}
          >
            {acceptButtonText}
          </Button>
          <Button
            sx={{ p: 1 }}
            color="secondary"
            variant="outlined"
            onClick={handleReject}
          >
            Cancel
          </Button>
        </DialogActions>
        <Loader active={processing} />
      </Dialog>
    ),
  };
};
