import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/ErrorOutlineRounded';
import {
  Box,
  FormHelperText,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { Input, useValueInput } from '@/entities/datasources';
import { useAppDispatch } from '@/shared/model';
import { Loader } from '@/shared/ui';
import { submitGooglePlayConsoleReportingIntegration } from '../../../../model/actions';

interface Props {
  onClose: () => void;
}

export function AddRecordRow({ onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [processing, setProcessing] = useState(false);
  const serviceCredentialsValidator = useCallback((value: string) => {
    try {
      JSON.parse(value);

      return false;
    } catch (error) {
      return true;
    }
  }, []);
  const nameInputProps = useValueInput();
  const cloudStorageURIInputProps = useValueInput();
  const serviceCredentialsInputProps = useValueInput({
    validator: serviceCredentialsValidator,
  });
  const hasError =
    nameInputProps.error ||
    cloudStorageURIInputProps.error ||
    serviceCredentialsInputProps.error;

  const handleCreate = () => {
    if (hasError) {
      return;
    }

    setProcessing(true);
    dispatch(
      submitGooglePlayConsoleReportingIntegration({
        name: nameInputProps.value,
        cloudStorageURI: cloudStorageURIInputProps.value,
        serviceCredentials: serviceCredentialsInputProps.value,
      }),
    )
      .then(onClose)
      .catch(() => {
        enqueueSnackbar('Unable to add record. Please, try again.', {
          variant: 'error',
          autoHideDuration: 4000,
        });
        setProcessing(false);
      });
  };
  return (
    <TableRow sx={{ position: 'relative' }}>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <Input
          {...nameInputProps}
          endAdornment={
            <Tooltip
              title={
                <Typography variant="caption">
                  Just a label to distinguish different records. <br />
                  To update existing record, use the value of the <b>Name</b> of
                  the record you want to update.
                </Typography>
              }
            >
              <InfoIcon color="info" fontSize="small" />
            </Tooltip>
          }
        />
        {nameInputProps.error && (
          <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
            Could not be empty.
          </FormHelperText>
        )}
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <Input {...cloudStorageURIInputProps} />
        {cloudStorageURIInputProps.error && (
          <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
            Could not be empty.
          </FormHelperText>
        )}
      </TableCell>
      <TableCell>
        <TextField
          autoComplete="off"
          fullWidth
          multiline
          minRows={5}
          maxRows={15}
          sx={{
            p: 0,
            '& .MuiInputBase-root': {
              padding: (theme) => `${theme.spacing(1)} ${theme.spacing(1.5)}`,
              fontSize: '14px',
            },
          }}
          helperText={
            serviceCredentialsInputProps.error
              ? 'Please enter a valid JSON'
              : ''
          }
          {...serviceCredentialsInputProps}
        />
      </TableCell>
      <TableCell sx={{ px: 0, verticalAlign: 'top' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <IconButton
              sx={{ color: (theme) => theme.palette.success.main }}
              size="small"
              disabled={hasError}
              onClick={handleCreate}
            >
              <CheckIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon fontSize="small" color="error" />
            </IconButton>
          </Box>
        </Box>
        <Loader active={processing} />
      </TableCell>
    </TableRow>
  );
}
