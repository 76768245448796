import { DataStatus, isError, isFinished, isLoading } from '@/shared/lib';
import { createAppSlice } from '@/shared/model';
import * as DatasourcesAPI from '../api/datasourcesAPI';
import type { Integration } from './types';

interface State {
  integrations: Integration[];
  status: DataStatus;
}

const initialState: State = {
  integrations: [],
  status: DataStatus.idle,
};

export const datasourcesSlice = createAppSlice({
  name: 'datasources',
  initialState,
  reducers: (create) => ({
    setupIntegrations: create.asyncThunk(
      (_: void) => {
        return DatasourcesAPI.getIntegrations();
      },
      {
        pending: (state) => {
          state.status = DataStatus.loading;
        },
        fulfilled: (state, action) => {
          state.integrations = action.payload;
          state.status = DataStatus.finished;
        },
        rejected: (state) => {
          state.status = DataStatus.error;
        },
        options: {
          condition: (_, { getState }) => {
            const state = getState() as RootState;

            if (isLoading(state.datasources.status)) {
              return false;
            }
          },
        },
      },
    ),
    addOrUpdateIntegration: create.reducer<Integration>((state, action) => {
      let updated = false;

      state.integrations = state.integrations.map((integration) => {
        if (integration.id === action.payload.id) {
          updated = true;

          return action.payload;
        }

        return integration;
      });

      if (!updated) {
        state.integrations.push(action.payload);
      }
    }),
    startIntegrationUpdate: create.reducer<string>((state, action) => {
      state.integrations = state.integrations.map((integration) => {
        if (integration.id === action.payload) {
          return {
            ...integration,
            updating: true,
          };
        }

        return integration;
      });
    }),
    stopIntegrationUpdate: create.reducer<string>((state, action) => {
      state.integrations = state.integrations.map((integration) => {
        if (integration.id === action.payload) {
          return {
            ...integration,
            updating: false,
          };
        }

        return integration;
      });
    }),
    deleteIntegration: create.asyncThunk<string>(
      (id) => {
        return DatasourcesAPI.deleteIntegration(id);
      },
      {
        fulfilled: (state, action) => {
          state.integrations = state.integrations.filter((integration) => {
            return integration.id !== action.meta.arg;
          });
        },
      },
    ),
  }),
  selectors: {
    selectIntegrations: (state) => {
      return state.integrations;
    },
    selectIsLoading: (state) => {
      return isLoading(state.status);
    },
    selectIsFailed: (state) => {
      return isError(state.status);
    },
    selectIsFinished: (state) => {
      return isFinished(state.status);
    },
  },
});

export const {
  setupIntegrations,
  addOrUpdateIntegration,
  deleteIntegration,
  startIntegrationUpdate,
  stopIntegrationUpdate,
} = datasourcesSlice.actions;
export const {
  selectIsFailed,
  selectIsFinished,
  selectIsLoading,
  selectIntegrations,
} = datasourcesSlice.selectors;
