import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/ErrorOutlineRounded';
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Input, useValueInput } from '@/entities/datasources';
import { useAppDispatch } from '@/shared/model';
import { Loader } from '@/shared/ui';
import { submitAppStoreConnectReportingIntegration } from '../../../../model/actions';

interface Props {
  onClose: () => void;
}

export function AddRecordRow({ onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [processing, setProcessing] = useState(false);
  const nameInputProps = useValueInput();
  const keyIdInputProps = useValueInput();
  const issuerIdInputProps = useValueInput();
  const vendorIdInputProps = useValueInput();
  const p8KeyInputProps = useValueInput();
  const hasError =
    nameInputProps.error ||
    keyIdInputProps.error ||
    issuerIdInputProps.error ||
    vendorIdInputProps.error ||
    p8KeyInputProps.error;

  const handleCreate = () => {
    if (hasError) {
      return;
    }

    setProcessing(true);
    dispatch(
      submitAppStoreConnectReportingIntegration({
        name: nameInputProps.value,
        keyId: keyIdInputProps.value,
        issuerId: issuerIdInputProps.value,
        vendorId: vendorIdInputProps.value,
        p8Key: p8KeyInputProps.value,
      }),
    )
      .then(onClose)
      .catch(() => {
        enqueueSnackbar('Unable to add record. Please, try again.', {
          variant: 'error',
          autoHideDuration: 4000,
        });
        setProcessing(false);
      });
  };

  return (
    <TableRow sx={{ position: 'relative' }}>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <Input
          {...nameInputProps}
          endAdornment={
            <Tooltip
              title={
                <Typography variant="caption">
                  Just a label to distinguish different records. <br />
                  To update existing record, use the value of the <b>Name</b> of
                  the record you want to update.
                </Typography>
              }
            >
              <InfoIcon color="info" fontSize="small" />
            </Tooltip>
          }
        />
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <Input {...keyIdInputProps} />
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <Input {...issuerIdInputProps} />
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <Input {...vendorIdInputProps} />
      </TableCell>
      <TableCell>
        <TextField
          autoComplete="off"
          fullWidth
          multiline
          minRows={5}
          maxRows={15}
          sx={{
            p: 0,
            '& .MuiInputBase-root': {
              padding: (theme) => `${theme.spacing(1)} ${theme.spacing(1.5)}`,
              fontSize: '14px',
            },
          }}
          {...p8KeyInputProps}
        />
      </TableCell>
      <TableCell sx={{ px: 0, verticalAlign: 'top' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <IconButton
              sx={{ color: (theme) => theme.palette.success.main }}
              size="small"
              disabled={hasError}
              onClick={handleCreate}
            >
              <CheckIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon fontSize="small" color="error" />
            </IconButton>
          </Box>
          {hasError && (
            <Typography color="error" variant="caption">
              Please, fill values in all fields
            </Typography>
          )}
        </Box>
        <Loader active={processing} />
      </TableCell>
    </TableRow>
  );
}
