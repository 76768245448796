import type { SignInWithPasswordCredentials } from '@supabase/supabase-js';
import { baseApi, getErrorMessage } from '@/shared/api';
import { mapOrganization } from '../lib/mapOrganization';
import type { OrganizationDTO } from './types';

export const getUserData = async () => {
  const { data: sessionData, error: sessionError } =
    await baseApi.auth.getSession();

  if (sessionError) {
    throw sessionError;
  }

  if (
    sessionData.session &&
    getRememberMe(sessionData.session.user.email || '')
  ) {
    return sessionData.session.user;
  }

  const { data: userData, error: userError } = await baseApi.auth.getUser();

  if (userError) {
    // skip throw when user unauthorized
    if (userError.status === 403) {
      return;
    }

    throw userError;
  }

  return userData.user;
};

export const login = async (credentials: SignInWithPasswordCredentials) => {
  const { data, error } = await baseApi.auth.signInWithPassword(credentials);

  if (error) {
    throw new Error(error.message);
  }

  return data.user;
};

export const logout = async () => {
  const { error } = await baseApi.auth.signOut();

  if (error) {
    throw error;
  }
};

export const changePassword = async (newPassword: string) => {
  const { error } = await baseApi.auth.updateUser({
    password: newPassword,
  });

  if (error) {
    throw error;
  }
};

export const requestResetPassword = async (email: string) => {
  const { error } = await baseApi.auth.resetPasswordForEmail(email);

  if (error) {
    throw error;
  }
};

const REMEMBER_ME_KEY = 'cw-remember-me';

const getRememberMe = (email: string): boolean => {
  const item = localStorage.getItem(REMEMBER_ME_KEY);

  return item === email;
};

export const updateRememberMe = (email: string) => {
  localStorage.setItem(REMEMBER_ME_KEY, email);
};

export const deleteRememberMe = () => {
  localStorage.removeItem(REMEMBER_ME_KEY);
};

const SHOW_PAYMENT_OVERDUE_BANNER_KEY = 'cw-show-payment-overdue-banner';

export const getShowPaymentOverdueBanner = (): boolean => {
  return localStorage.getItem(SHOW_PAYMENT_OVERDUE_BANNER_KEY) === 'true';
};

export const setShowPaymentOverdueBanner = (
  showPaymentOverdueBanner: boolean,
) => {
  const currentShowPaymentOverdueBannerValue = localStorage.getItem(
    SHOW_PAYMENT_OVERDUE_BANNER_KEY,
  );

  if (
    !showPaymentOverdueBanner ||
    currentShowPaymentOverdueBannerValue === 'false'
  ) {
    return;
  }

  localStorage.setItem(SHOW_PAYMENT_OVERDUE_BANNER_KEY, 'true');
};

export const hidePaymentOverdueBanner = () => {
  localStorage.setItem(SHOW_PAYMENT_OVERDUE_BANNER_KEY, 'false');
};

export const deleteShowPaymentOverdueBanner = () => {
  localStorage.removeItem(SHOW_PAYMENT_OVERDUE_BANNER_KEY);
};

export const getOrganization = async () => {
  const { data, error } = await baseApi
    .from('organizations')
    .select('*')
    .returns<[OrganizationDTO]>();

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  if (!data[0]) {
    return;
  }

  return mapOrganization(data[0]);
};

export const getAnalyticsId = async (id: string) => {
  const { data, error } = await baseApi
    .from('analytics')
    .select('*')
    .eq('user_id', id)
    .returns<
      [
        {
          id: string;
          analytics_id: string;
        },
      ]
    >();

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return data[0].id;
};
