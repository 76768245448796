import type { ButtonProps } from '@mui/material';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import {
  TIK_TOK_FOR_BUSINESS_REPORTING_INTEGRATION_ID,
  deleteIntegration,
  IntegrationActionsMenu,
  selectIntegrations,
  selectIsLoading,
} from '@/entities/datasources';
import { formatDate } from '@/shared/lib';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Button, Loader } from '@/shared/ui';
import type { TikTokForBusinessReportingIntegration } from '../../model/types';
import { useURLSearchParamsBroadcaster } from '../../model/useURLSearchParamsBroadcaster';
import { BROADCAST_CHANNEL_NAME } from './model/constants';
import { useTikTokAuthCode } from './model/useTikTokAuthCode';
import { TikTokIcon } from './ui/TikTokIcon/TikTokIcon';

const TikTokButton = styled((props: ButtonProps) => {
  return <Button variant="outlined" color="secondary" {...props} />;
})(() => ({
  color: '#161823',
})) as typeof Button;

export function TikTokForBusinessDatasource() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const integrationsLoading = useAppSelector(selectIsLoading);
  const integrations = useAppSelector(selectIntegrations);
  const tikTokForBusinessReportingIntegrations = useMemo(() => {
    return integrations.filter((integration) => {
      return (
        integration.sourceIntegrationId ===
        TIK_TOK_FOR_BUSINESS_REPORTING_INTEGRATION_ID
      );
    }) as TikTokForBusinessReportingIntegration[];
  }, [integrations]);
  const { processing, openDataAccessDialog } = useTikTokAuthCode();

  useURLSearchParamsBroadcaster({
    channelName: BROADCAST_CHANNEL_NAME,
    params: ['code', 'auth_code'],
  });

  const handleDeleteIntegration = (id: string) => () => {
    return dispatch(deleteIntegration(id))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Integration successfully deleted.', {
          variant: 'success',
          autoHideDuration: 2000,
        });
      })
      .catch(() => {
        enqueueSnackbar('Unable to delete integration. Please, try again.', {
          variant: 'error',
          autoHideDuration: 4000,
        });
      });
  };

  return (
    <Box
      sx={{
        py: 3,
        px: 5,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Last update date</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tikTokForBusinessReportingIntegrations.length === 0 && (
            <TableRow>
              <TableCell colSpan={3} align="center">
                No accounts added
              </TableCell>
            </TableRow>
          )}
          {tikTokForBusinessReportingIntegrations.map((integration) => {
            return (
              <TableRow key={integration.id} sx={{ position: 'relative' }}>
                <TableCell>{integration.integrationConfig.email}</TableCell>
                <TableCell>{formatDate(integration.updatedAt)}</TableCell>
                <TableCell>
                  <IntegrationActionsMenu
                    integrationName={integration.integrationConfig.email}
                    onDelete={handleDeleteIntegration(integration.id)}
                  />
                  {/* Moved to cell to avoid warning about <div> cannot be child of <tr> */}
                  <Loader active={integration.updating} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TikTokButton startIcon={<TikTokIcon />} onClick={openDataAccessDialog}>
        Add account
      </TikTokButton>
      <Loader active={processing || integrationsLoading} />
    </Box>
  );
}
