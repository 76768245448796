import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import {
  GOOGLE_ADS_REPORTING_INTEGRATION_ID,
  deleteIntegration,
  IntegrationActionsMenu,
  selectIntegrations,
  selectIsLoading,
} from '@/entities/datasources';
import { formatDate } from '@/shared/lib';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Button, Loader } from '@/shared/ui';
import { updateGoogleAdsReportingIntegrationAccountListActiveRecord } from '../../model/actions';
import type {
  ActiveStatusRecord,
  GoogleAdsReportingIntegration,
} from '../../model/types';
import { useURLSearchParamsBroadcaster } from '../../model/useURLSearchParamsBroadcaster';
import { AccountsActiveStatusList } from '../AccountsActiveStatusList/AccountsActiveStatusList';
import { BROADCAST_CHANNEL_NAME } from './model/constants';
import { useGoogleAdsAuthCode } from './model/useGoogleAdsAuthCode';
import { AccountInfoLabel } from './ui/AccountInfoLabel/AccountInfoLabel';
import { GoogleIcon } from './ui/GoogleIcon/GoogleIcon';

export function GoogleAdsDatasource() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const integrationsLoading = useAppSelector(selectIsLoading);
  const integrations = useAppSelector(selectIntegrations);
  const googleAdsReportingIntegrations = useMemo(() => {
    return integrations.filter((integration) => {
      return (
        integration.sourceIntegrationId === GOOGLE_ADS_REPORTING_INTEGRATION_ID
      );
    }) as GoogleAdsReportingIntegration[];
  }, [integrations]);
  const { processing, openDataAccessDialog } = useGoogleAdsAuthCode();

  useURLSearchParamsBroadcaster({
    channelName: BROADCAST_CHANNEL_NAME,
    params: ['code'],
  });

  const handleDeleteIntegration = (id: string) => () => {
    return dispatch(deleteIntegration(id))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Integration successfully deleted.', {
          variant: 'success',
          autoHideDuration: 2000,
        });
      })
      .catch(() => {
        enqueueSnackbar('Unable to delete integration. Please, try again.', {
          variant: 'error',
          autoHideDuration: 4000,
        });
      });
  };
  const handleUpdateAccountsActiveStatusRecord =
    (id: string) => (newActiveStatusRecord: ActiveStatusRecord) => {
      dispatch(
        updateGoogleAdsReportingIntegrationAccountListActiveRecord({
          id,
          activeStatusRecord: newActiveStatusRecord,
        }),
      ).catch(() => {
        enqueueSnackbar('Unable to update accounts list. Please, try again.', {
          variant: 'error',
          autoHideDuration: 4000,
        });
      });
    };

  return (
    <Box
      sx={{
        py: 3,
        px: 5,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Customers</TableCell>
            <TableCell>Last update date</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {googleAdsReportingIntegrations.length === 0 && (
            <TableRow>
              <TableCell colSpan={3} align="center">
                No accounts added
              </TableCell>
            </TableRow>
          )}
          {googleAdsReportingIntegrations.map((integration) => {
            return (
              <TableRow key={integration.id} sx={{ position: 'relative' }}>
                <TableCell>{integration.integrationConfig.email}</TableCell>
                <TableCell>
                  <AccountsActiveStatusList
                    LabelComponent={AccountInfoLabel}
                    allAcounts={integration.integrationConfig.accountList.map(
                      (account) => {
                        return {
                          id: account,
                          name: account,
                        };
                      },
                    )}
                    value={
                      integration.integrationConfig
                        .accountListActiveStatusRecord
                    }
                    onChange={handleUpdateAccountsActiveStatusRecord(
                      integration.id,
                    )}
                  />
                </TableCell>
                <TableCell>{formatDate(integration.updatedAt)}</TableCell>
                <TableCell>
                  <IntegrationActionsMenu
                    integrationName={integration.integrationConfig.email}
                    onDelete={handleDeleteIntegration(integration.id)}
                  />
                  {/* Moved to cell to avoid warning about <div> cannot be child of <tr> */}
                  <Loader active={integration.updating} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Button
        startIcon={<GoogleIcon />}
        variant="outlined"
        color="inherit"
        onClick={openDataAccessDialog}
      >
        Add account
      </Button>
      <Loader active={processing || integrationsLoading} />
    </Box>
  );
}
