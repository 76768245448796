import type { OutlinedInputProps } from '@mui/material';
import { OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Input = styled((props: OutlinedInputProps) => {
  return <OutlinedInput fullWidth size="small" {...props} />;
})(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    fontSize: '14px',
  },
}));
