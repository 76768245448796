import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import '@fontsource/roboto/500.css';
import { ROUTES } from '@/shared/constants';
import { MaskedInput, RequiredAsterisk } from '@/shared/ui';

const Wrapper = styled('label')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));
const LabelWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.25),
}));
const ForgotPasswordLink = styled(Link)(({ theme }) => ({
  marginLeft: 'auto',
  fontSize: '12px',
  lineHeight: '20px',
  color: theme.palette.primary.main,
  textDecoration: 'none',
}));

export function PasswordInput() {
  return (
    <Wrapper>
      <LabelWrapper>
        <RequiredAsterisk />
        <Typography variant="label">Password</Typography>
        <ForgotPasswordLink to={ROUTES.passwordReset}>
          Forgot password
        </ForgotPasswordLink>
      </LabelWrapper>
      <MaskedInput name="password" required />
    </Wrapper>
  );
}
