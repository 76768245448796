import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useAppDispatch } from '@/shared/model';
import { submitGoogleAdsReportingIntegration } from '../../../model/actions';
import { BROADCAST_CHANNEL_NAME } from './constants';

interface GoogleAdsAuthCodeState {
  processing: boolean;
  openDataAccessDialog: () => void;
}

export const useGoogleAdsAuthCode = (): GoogleAdsAuthCodeState => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [processing, setProcessing] = useState(false);

  return {
    processing,
    openDataAccessDialog: () => {
      const clientId =
        '203540375479-3ahnv3u4a7vvth8omabpsev8rje6dijq.apps.googleusercontent.com';
      const redirectURI = window.location.href;
      const url = `https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?access_type=offline&client_id=${clientId}&redirect_uri=${redirectURI}&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadwords`;
      const dataAccessWindow = window.open(url, '_blank');
      const broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
      const messageHandler = (event: MessageEvent<{ code: string }>) => {
        const { code } = event.data;

        dataAccessWindow?.close();
        broadcastChannel.removeEventListener('message', messageHandler);

        setProcessing(true);
        dispatch(
          submitGoogleAdsReportingIntegration({
            clientId,
            code,
            redirectURI,
          }),
        )
          .catch(() => {
            enqueueSnackbar('Unable to add account. Please, try again.', {
              variant: 'error',
              autoHideDuration: 4000,
            });
          })
          .finally(() => {
            setProcessing(false);
          });
      };

      broadcastChannel.addEventListener('message', messageHandler);
    },
  };
};
