import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/ErrorOutlineRounded';
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Input, useValueInput } from '@/entities/datasources';
import { useAppDispatch } from '@/shared/model';
import { Loader, MaskedInput } from '@/shared/ui';
import { submitIronSourceIntegration } from '../../../../model/actions';

interface Props {
  onClose: () => void;
}

export function AddRecordRow({ onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [processing, setProcessing] = useState(false);
  const accountNameInputProps = useValueInput();
  const secretKeyInputProps = useValueInput();
  const refreshTokenInputProps = useValueInput();
  const hasError =
    accountNameInputProps.error ||
    secretKeyInputProps.error ||
    refreshTokenInputProps.error;

  const handleCreate = () => {
    if (hasError) {
      return;
    }

    setProcessing(true);
    dispatch(
      submitIronSourceIntegration({
        accountName: accountNameInputProps.value,
        secretKey: secretKeyInputProps.value,
        refreshToken: refreshTokenInputProps.value,
      }),
    )
      .then(onClose)
      .catch(() => {
        enqueueSnackbar('Unable to add record. Please, try again.', {
          variant: 'error',
          autoHideDuration: 4000,
        });
        setProcessing(false);
      });
  };

  return (
    <TableRow sx={{ position: 'relative' }}>
      <TableCell>
        <Input
          {...accountNameInputProps}
          endAdornment={
            <Tooltip
              title={
                <Typography variant="caption">
                  Just a label to distinguish different keys. <br />
                  To update existing record, use the value of the{' '}
                  <b>Account Name</b> of the record you want to update.
                </Typography>
              }
            >
              <InfoIcon color="info" fontSize="small" />
            </Tooltip>
          }
        />
      </TableCell>
      <TableCell>
        <MaskedInput autoComplete="off" fullWidth {...secretKeyInputProps} />
      </TableCell>
      <TableCell>
        <MaskedInput autoComplete="off" fullWidth {...refreshTokenInputProps} />
      </TableCell>
      <TableCell sx={{ px: 0, py: 0.5 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <IconButton
              sx={{ color: (theme) => theme.palette.success.main }}
              size="small"
              disabled={hasError}
              onClick={handleCreate}
            >
              <CheckIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon fontSize="small" color="error" />
            </IconButton>
          </Box>
          {hasError && (
            <Typography color="error" variant="caption">
              Please, fill values in all fields
            </Typography>
          )}
        </Box>
        <Loader active={processing} />
      </TableCell>
    </TableRow>
  );
}
