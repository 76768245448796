import type {
  APP_STORE_CONNECT_REPORTING_INTEGRATION_ID,
  APPLE_SEARCH_ADS_REPORTING_INTEGRATION_ID,
  APPLOVIN_ADS_REPORTING_INTEGRATION_ID,
  GOOGLE_ADS_REPORTING_INTEGRATION_ID,
  GOOGLE_PLAY_CONSOLE_REPORTING_INTEGRATION_ID,
  MOLOCO_ADS_REPORTING_INTEGRATION_ID,
  META_ADS_REPORTING_INTEGRATION_ID,
  PAY_PAL_REPORTING_INTEGRATION_ID,
  SNAPCHAT_ADS_REPORTING_INTEGRATION_ID,
  STRIPE_REPORTING_INTEGRATION_ID,
  TIK_TOK_FOR_BUSINESS_REPORTING_INTEGRATION_ID,
  UNITY_ADS_REPORTING_INTEGRATION_ID,
  IRON_SOURCE_REPORTING_INTEGRATION_ID,
  X_ADS_REPORTING_INTEGRATION_ID,
  LIFTOFF_REPORTING_INTEGRATION_ID,
  APPSFLYER_REPORTING_INTEGRATION_ID,
  GOOGLE_ANALYTICS_REPORTING_INTEGRATION_ID,
  MINTEGRAL_REPORTING_INTEGRATION_ID,
} from './constants';

export enum DatasourceType {
  marketingSpend = 'marketingSpend',
  attribution = 'attribution',
  monetization = 'monetization',
}

export enum DatasourceStatus {
  inactive = 'inactive',
  active = 'active',
  error = 'error',
}

export interface Datasource {
  id: string;
  name: string;
  type: DatasourceType;
  iconUrl: string;
  status: DatasourceStatus;
  sourceIntegrationIds: string[];
  howToSetupLink?: string;
}

export interface Integration<T = unknown> {
  id: string;
  sourceIntegrationId:
    | typeof APP_STORE_CONNECT_REPORTING_INTEGRATION_ID
    | typeof APPLE_SEARCH_ADS_REPORTING_INTEGRATION_ID
    | typeof APPLOVIN_ADS_REPORTING_INTEGRATION_ID
    | typeof APPSFLYER_REPORTING_INTEGRATION_ID
    | typeof GOOGLE_ADS_REPORTING_INTEGRATION_ID
    | typeof GOOGLE_ANALYTICS_REPORTING_INTEGRATION_ID
    | typeof GOOGLE_PLAY_CONSOLE_REPORTING_INTEGRATION_ID
    | typeof IRON_SOURCE_REPORTING_INTEGRATION_ID
    | typeof LIFTOFF_REPORTING_INTEGRATION_ID
    | typeof META_ADS_REPORTING_INTEGRATION_ID
    | typeof MINTEGRAL_REPORTING_INTEGRATION_ID
    | typeof MOLOCO_ADS_REPORTING_INTEGRATION_ID
    | typeof PAY_PAL_REPORTING_INTEGRATION_ID
    | typeof SNAPCHAT_ADS_REPORTING_INTEGRATION_ID
    | typeof STRIPE_REPORTING_INTEGRATION_ID
    | typeof TIK_TOK_FOR_BUSINESS_REPORTING_INTEGRATION_ID
    | typeof UNITY_ADS_REPORTING_INTEGRATION_ID
    | typeof X_ADS_REPORTING_INTEGRATION_ID;
  integrationConfig: T;
  updatedAt: string;
  updating: boolean;
}
