import { useEffect, useRef } from 'react';

interface Params {
  channelName: string;
  params: string[];
}

export const useURLSearchParamsBroadcaster = ({
  channelName,
  params,
}: Params) => {
  const isCalledRef = useRef(false);
  const urlParams = new URLSearchParams(window.location.search);
  const paramsValues = params.reduce<(string | null)[]>((acc, curr) => {
    acc.push(urlParams.get(curr));

    return acc;
  }, []);

  useEffect(
    () => {
      if (!paramsValues.every(Boolean) || isCalledRef.current) {
        return;
      }

      const broadcastChannel = new BroadcastChannel(channelName);

      isCalledRef.current = true;
      broadcastChannel.postMessage(
        Object.fromEntries(
          params.map((param, index) => {
            return [param, paramsValues[index]];
          }),
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...paramsValues, ...params, channelName],
  );
};
