import { useMemo } from 'react';
import type { Datasource, Integration } from '@/entities/datasources';
import {
  APP_STORE_CONNECT_REPORTING_INTEGRATION_ID,
  APPLE_SEARCH_ADS_REPORTING_INTEGRATION_ID,
  APPLOVIN_ADS_REPORTING_INTEGRATION_ID,
  GOOGLE_ADS_REPORTING_INTEGRATION_ID,
  GOOGLE_PLAY_CONSOLE_REPORTING_INTEGRATION_ID,
  META_ADS_REPORTING_INTEGRATION_ID,
  PAY_PAL_REPORTING_INTEGRATION_ID,
  SNAPCHAT_ADS_REPORTING_INTEGRATION_ID,
  STRIPE_REPORTING_INTEGRATION_ID,
  TIK_TOK_FOR_BUSINESS_REPORTING_INTEGRATION_ID,
  DatasourceStatus,
  DatasourceType,
  selectIntegrations,
  UNITY_ADS_REPORTING_INTEGRATION_ID,
  MOLOCO_ADS_REPORTING_INTEGRATION_ID,
  IRON_SOURCE_REPORTING_INTEGRATION_ID,
  X_ADS_REPORTING_INTEGRATION_ID,
  LIFTOFF_REPORTING_INTEGRATION_ID,
  APPSFLYER_REPORTING_INTEGRATION_ID,
  GOOGLE_ANALYTICS_REPORTING_INTEGRATION_ID,
  MINTEGRAL_REPORTING_INTEGRATION_ID,
} from '@/entities/datasources';
import { useAppSelector } from '@/shared/model';
import { getDatasourceStatus } from './getDatasourceStatus';
import { getIntegrationStatus } from './getIntegrationStatus';

export enum DatasourceIds {
  appStoreConnect = 'app-store-connect',
  appLovinAds = 'app-lovin-ads',
  appleSearchAds = 'apple-search-ads',
  appsflyer = 'appsflyer',
  googleAds = 'google-ads',
  googleAnalytics = 'google-analytics',
  googlePlayConsole = 'google-play-console',
  ironSource = 'iron-source',
  liftoff = 'liftoff',
  metaAds = 'meta-ads',
  mintegral = 'mintegral',
  molocoAds = 'moloco-ads',
  payPal = 'pay-pal',
  snapchatAds = 'snapchat-ads',
  stripe = 'stripe',
  tikTokForBusiness = 'tik-tok-for-business',
  unityAds = 'unity-ads',
  xAds = 'x-ads',
}

const AVAILABLE_DATASOURCES: Datasource[] = [
  {
    id: DatasourceIds.appStoreConnect,
    name: 'App Store Connect',
    sourceIntegrationIds: [APP_STORE_CONNECT_REPORTING_INTEGRATION_ID],
    type: DatasourceType.monetization,
    iconUrl: '/images/datasourceIcons/app-store-connect-logo.png',
    status: DatasourceStatus.inactive,
  },
  {
    id: DatasourceIds.appleSearchAds,
    name: 'Apple Search Ads',
    sourceIntegrationIds: [APPLE_SEARCH_ADS_REPORTING_INTEGRATION_ID],
    type: DatasourceType.marketingSpend,
    iconUrl: '/images/datasourceIcons/apple-search-ads.png',
    status: DatasourceStatus.inactive,
  },
  {
    id: DatasourceIds.appLovinAds,
    name: 'AppLovin Ads',
    sourceIntegrationIds: [APPLOVIN_ADS_REPORTING_INTEGRATION_ID],
    type: DatasourceType.marketingSpend,
    iconUrl: '/images/datasourceIcons/app-lovin-logo.png',
    status: DatasourceStatus.inactive,
  },
  {
    id: DatasourceIds.appsflyer,
    name: 'Appsflyer',
    sourceIntegrationIds: [APPSFLYER_REPORTING_INTEGRATION_ID],
    type: DatasourceType.attribution,
    iconUrl: '/images/datasourceIcons/appsflyer-logo.png',
    status: DatasourceStatus.inactive,
  },
  {
    id: DatasourceIds.googleAds,
    name: 'Google Ads',
    sourceIntegrationIds: [GOOGLE_ADS_REPORTING_INTEGRATION_ID],
    type: DatasourceType.marketingSpend,
    iconUrl: '/images/datasourceIcons/google-ads.png',
    status: DatasourceStatus.inactive,
  },
  {
    id: DatasourceIds.googleAnalytics,
    name: 'Google Analytics',
    sourceIntegrationIds: [GOOGLE_ANALYTICS_REPORTING_INTEGRATION_ID],
    type: DatasourceType.attribution,
    iconUrl: '/images/datasourceIcons/google-analytics-logo.png',
    status: DatasourceStatus.inactive,
  },
  {
    id: DatasourceIds.googlePlayConsole,
    name: 'Google Play Console',
    sourceIntegrationIds: [GOOGLE_PLAY_CONSOLE_REPORTING_INTEGRATION_ID],
    type: DatasourceType.monetization,
    iconUrl: '/images/datasourceIcons/google-play-console-logo.png',
    status: DatasourceStatus.inactive,
  },
  {
    id: DatasourceIds.ironSource,
    name: 'ironSource',
    sourceIntegrationIds: [IRON_SOURCE_REPORTING_INTEGRATION_ID],
    type: DatasourceType.marketingSpend,
    iconUrl: '/images/datasourceIcons/ironsource-logo.png',
    status: DatasourceStatus.inactive,
  },
  {
    id: DatasourceIds.liftoff,
    name: 'liftoff',
    sourceIntegrationIds: [LIFTOFF_REPORTING_INTEGRATION_ID],
    type: DatasourceType.marketingSpend,
    iconUrl: '/images/datasourceIcons/liftoff-logo.png',
    status: DatasourceStatus.inactive,
  },
  {
    id: DatasourceIds.metaAds,
    name: 'Meta Ads',
    sourceIntegrationIds: [META_ADS_REPORTING_INTEGRATION_ID],
    type: DatasourceType.marketingSpend,
    iconUrl: '/images/datasourceIcons/meta.png',
    status: DatasourceStatus.inactive,
  },
  {
    id: DatasourceIds.mintegral,
    name: 'Mintegral',
    sourceIntegrationIds: [MINTEGRAL_REPORTING_INTEGRATION_ID],
    type: DatasourceType.marketingSpend,
    iconUrl: '/images/datasourceIcons/mintegral-logo.png',
    status: DatasourceStatus.inactive,
  },
  {
    id: DatasourceIds.molocoAds,
    name: 'Moloco Ads',
    sourceIntegrationIds: [MOLOCO_ADS_REPORTING_INTEGRATION_ID],
    type: DatasourceType.marketingSpend,
    iconUrl: '/images/datasourceIcons/moloco-ads-logo.png',
    status: DatasourceStatus.inactive,
  },
  {
    id: DatasourceIds.payPal,
    name: 'PayPal',
    sourceIntegrationIds: [PAY_PAL_REPORTING_INTEGRATION_ID],
    type: DatasourceType.monetization,
    iconUrl: '/images/datasourceIcons/pay-pal-logo.png',
    status: DatasourceStatus.inactive,
  },
  {
    id: DatasourceIds.snapchatAds,
    name: 'Snapchat Ads',
    sourceIntegrationIds: [SNAPCHAT_ADS_REPORTING_INTEGRATION_ID],
    type: DatasourceType.marketingSpend,
    iconUrl: '/images/datasourceIcons/snapchat-logo.png',
    status: DatasourceStatus.inactive,
  },
  {
    id: DatasourceIds.stripe,
    name: 'Stripe',
    sourceIntegrationIds: [STRIPE_REPORTING_INTEGRATION_ID],
    type: DatasourceType.monetization,
    iconUrl: '/images/datasourceIcons/stripe-logo.png',
    status: DatasourceStatus.inactive,
  },
  {
    id: DatasourceIds.tikTokForBusiness,
    name: 'TikTok for Business',
    sourceIntegrationIds: [TIK_TOK_FOR_BUSINESS_REPORTING_INTEGRATION_ID],
    type: DatasourceType.marketingSpend,
    iconUrl: '/images/datasourceIcons/tiktok-for-business.png',
    status: DatasourceStatus.inactive,
  },
  {
    id: DatasourceIds.unityAds,
    name: 'Unity Ads',
    sourceIntegrationIds: [UNITY_ADS_REPORTING_INTEGRATION_ID],
    type: DatasourceType.marketingSpend,
    iconUrl: '/images/datasourceIcons/unity-ads-logo.png',
    status: DatasourceStatus.inactive,
  },
  {
    id: DatasourceIds.xAds,
    name: 'X Ads (Twitter Ads)',
    sourceIntegrationIds: [X_ADS_REPORTING_INTEGRATION_ID],
    type: DatasourceType.marketingSpend,
    iconUrl: '/images/datasourceIcons/x-ads-logo.png',
    status: DatasourceStatus.inactive,
  },
];

export const useDatasources = (): Datasource[] => {
  const integrations = useAppSelector(selectIntegrations);
  const datasourcesWithActualStatus = useMemo(() => {
    const integrationsMap = integrations.reduce<Record<string, Integration[]>>(
      (acc, integration) => {
        if (!acc[integration.sourceIntegrationId]) {
          acc[integration.sourceIntegrationId] = [];
        }

        acc[integration.sourceIntegrationId].push(integration);

        return acc;
      },
      {},
    );

    return AVAILABLE_DATASOURCES.map((datasource) => {
      const relatedIntegrations = datasource.sourceIntegrationIds
        .map((id) => {
          return integrationsMap[id] || [];
        })
        .flat();
      const integrationStatuses = relatedIntegrations.map((integration) => {
        return getIntegrationStatus(integration);
      });

      return {
        ...datasource,
        status: getDatasourceStatus(integrationStatuses),
      };
    });
  }, [integrations]);

  return datasourcesWithActualStatus;
};
