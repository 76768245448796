import { Box, Typography } from '@mui/material';
import type { AccountInfo } from '../../../../model/types';

interface Props {
  accountInfo: AccountInfo;
}

export function AccountInfoLabel({ accountInfo }: Props) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
      {accountInfo.name}
      <Typography variant="caption">ID: {accountInfo.id}</Typography>
    </Box>
  );
}
