import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import type { ButtonProps } from '@mui/material';
import { Box, FormControlLabel, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import isEqual from 'lodash.isequal';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Button, Checkbox } from '@/shared/ui';
import type { AccountInfo, ActiveStatusRecord } from '../../model/types';

const ChangesButton = styled((props: ButtonProps) => {
  return <Button variant="outlined" color="secondary" {...props} />;
})(({ theme }) => ({
  padding: `0 ${theme.spacing(1)}`,
}));

interface Props {
  LabelComponent: FC<{ accountInfo: AccountInfo }>;
  allAcounts: AccountInfo[];
  value: ActiveStatusRecord;
  onChange: (v: ActiveStatusRecord) => void;
}

export function AccountsActiveStatusList({
  LabelComponent,
  allAcounts,
  value,
  onChange,
}: Props) {
  const [internalValue, setInternalValue] = useState(value);
  const hasUnsavedChanges = !isEqual(value, internalValue);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  if (allAcounts.length === 0) {
    return (
      <Typography color="secondary" variant="caption">
        No ad accounts linked.
      </Typography>
    );
  }

  const handleItemChange =
    (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.checked;

      if (newValue) {
        setInternalValue({
          ...internalValue,
          [id]: true,
        });
      } else {
        const { [id]: accountToRemove, ...newInternalValue } = internalValue;

        setInternalValue(newInternalValue);
      }
    };
  const handleResetChange = () => {
    setInternalValue(value);
  };
  const handleSubmitChange = () => {
    onChange(internalValue);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box
        component="ul"
        sx={{
          px: 2,
          py: 0,
          margin: 0,
          listStyle: 'none',
          maxHeight: 150,
          overflowY: 'auto',
        }}
      >
        {allAcounts.map((account) => {
          return (
            <li key={account.id}>
              <FormControlLabel
                sx={{
                  gap: 1,
                  alignItems: 'flex-start',
                }}
                label={<LabelComponent accountInfo={account} />}
                control={
                  <Checkbox
                    sx={{ mt: 0.5 }}
                    checked={internalValue[account.id] || false}
                    onChange={handleItemChange(account.id)}
                  />
                }
              />
            </li>
          );
        })}
      </Box>
      {hasUnsavedChanges && (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <ChangesButton onClick={handleSubmitChange}>
            <CheckIcon fontSize="small" color="success" />
            Save
          </ChangesButton>
          <ChangesButton onClick={handleResetChange}>
            <ClearIcon color="error" fontSize="small" />
            Cancel
          </ChangesButton>
        </Box>
      )}
    </Box>
  );
}
