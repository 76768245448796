import { Alert, AlertTitle } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import {
  hidePaymentOverdueBanner,
  selectShowPaymentOverdueBanner,
} from '../../model/slice';

export function PaymentOverdueBanner() {
  const dispatch = useAppDispatch();
  const showPaymentOverdueBanner = useAppSelector(
    selectShowPaymentOverdueBanner,
  );

  const handleClose = () => {
    dispatch(hidePaymentOverdueBanner());
  };

  if (!showPaymentOverdueBanner) {
    return null;
  }

  return (
    <Alert severity="warning" onClose={handleClose}>
      <AlertTitle>Payment Overdue</AlertTitle>
      Your payment is overdue. Please complete your payment to continue
      uninterrupted access to Campaignswell. <br /> If you’ve already paid or
      have any questions, contact{' '}
      <a href="mailto:support@campaignswell.com?subject=Payment overdue notification">
        support
      </a>
    </Alert>
  );
}
