import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useAppDispatch } from '@/shared/model';
import { submitMetaAdsReportingIntegration } from '../../../model/actions';
import { BROADCAST_CHANNEL_NAME } from './constants';

interface MetaOauthCodeState {
  processing: boolean;
  openDataAccessDialog: () => void;
}

export const useMetaOauthCode = (): MetaOauthCodeState => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [processing, setProcessing] = useState(false);

  return {
    processing,
    openDataAccessDialog: () => {
      const clientId = import.meta.env.VITE_META_ADS_REPORTING_CLIENT_ID;
      const redirectURI = window.location.href;
      const broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
      const dataAccessWindow = window.open(
        `https://www.facebook.com/v21.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectURI}&scope=ads_read`,
        '_blank',
      );
      const messageHandler = (event: MessageEvent<{ code: string }>) => {
        const { code } = event.data;

        dataAccessWindow?.close();
        broadcastChannel.removeEventListener('message', messageHandler);

        setProcessing(true);
        dispatch(
          submitMetaAdsReportingIntegration({
            clientId,
            code,
            redirectURI,
          }),
        )
          .catch(() => {
            enqueueSnackbar('Unable to add account. Please, try again.', {
              variant: 'error',
              autoHideDuration: 4000,
            });
          })
          .finally(() => {
            setProcessing(false);
          });
      };

      broadcastChannel.addEventListener('message', messageHandler);
    },
  };
};
