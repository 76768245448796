import { DatasourceStatus } from '@/entities/datasources';

export const getDatasourceStatus = (
  integrationStatuses: DatasourceStatus[],
) => {
  if (integrationStatuses.length === 0) {
    return DatasourceStatus.inactive;
  }

  let result = DatasourceStatus.inactive;

  for (let status of integrationStatuses) {
    if (status === DatasourceStatus.error) {
      result = DatasourceStatus.error;

      break;
    }

    if (status === DatasourceStatus.active) {
      result = DatasourceStatus.active;
    }
  }

  return result;
};
