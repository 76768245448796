import CompressIcon from '@mui/icons-material/Compress';
import ExpandIcon from '@mui/icons-material/Expand';
import { Box, Tooltip } from '@mui/material';
import { DEFAULT_COLUMN_WIDTH } from '@/shared/constants';
import { Analytics } from '@/shared/lib';
import { useDataGridApiRefContext } from '@/shared/ui';
import { Button } from '@/shared/ui';
import { DefaultWidthIcon } from '../DefaultWidthIcon/DefaultWidthIcon';

interface Props {
  onColumnsResize: (v: { name: string; width: number }[]) => void;
}

export function DataGridAutosize({ onColumnsResize }: Props) {
  const { apiRef } = useDataGridApiRefContext();

  const handleShrink = () => {
    if (!apiRef) {
      return;
    }

    // [HACK] to workaround buggy behavior with resize when view scrolled from top 0 left 0
    const initialScrollPosition = apiRef.getScrollPosition();
    const shrinkHandler = () => {
      apiRef
        .autosizeColumns({
          includeHeaders: false,
          includeOutliers: true,
        })
        // [HACK] investigate root cause why columns not sized properly on first call
        .then(() => {
          return apiRef.autosizeColumns({
            includeHeaders: false,
            includeOutliers: true,
          });
        })
        .then(() => {
          apiRef.scroll(initialScrollPosition);
        });
    };

    if (initialScrollPosition.left !== 0) {
      const unsubscribe = apiRef.subscribeEvent('scrollPositionChange', () => {
        unsubscribe();
        shrinkHandler();
      });
      apiRef.scroll({
        left: 0,
      });
    } else {
      shrinkHandler();
    }
    Analytics.sendFeatureUsage('data_grid_autosize', 'columns_shrink');
  };
  const handleExpand = () => {
    if (!apiRef) {
      return;
    }

    const initialScrollPosition = apiRef.getScrollPosition();
    apiRef
      .autosizeColumns({
        includeHeaders: true,
        includeOutliers: true,
      })
      .then(() => {
        apiRef.scroll(initialScrollPosition);
      });
    Analytics.sendFeatureUsage('data_grid_autosize', 'columns_expand');
  };
  const handleResizeToDefaultWidth = () => {
    if (!apiRef) {
      return;
    }

    const columns = apiRef.getAllColumns();
    const update = columns.map((column) => {
      return {
        ...column,
        width: DEFAULT_COLUMN_WIDTH,
      };
    });

    apiRef.updateColumns(update);
    // Need to update columns width manually, because `updateColumns` doesn't trigger `onColumnWidthChange` event
    onColumnsResize(
      update.map((column) => ({ name: column.field, width: column.width })),
    );
    Analytics.sendFeatureUsage('data_grid_autosize', 'columns_default_width');
  };

  return (
    <Box sx={{ display: 'flex', gap: 0.25 }}>
      <Tooltip title="Shrink columns to fit the values">
        <Button
          color="secondary"
          size="small"
          sx={{ p: 0, width: 38, minWidth: 0, backgroundColor: 'transparent' }}
          onClick={handleShrink}
        >
          <CompressIcon
            sx={{ fontSize: '1.25rem', transform: 'rotate(90deg)' }}
          />
        </Button>
      </Tooltip>
      <Tooltip title="Expand columns to fit the headers">
        <Button
          color="secondary"
          size="small"
          sx={{ p: 0, width: 38, minWidth: 0, backgroundColor: 'transparent' }}
          onClick={handleExpand}
        >
          <ExpandIcon
            sx={{ fontSize: '1.25rem', transform: 'rotate(90deg)' }}
          />
        </Button>
      </Tooltip>
      <Tooltip title="Restore default columns width">
        <Button
          color="secondary"
          size="small"
          sx={{ p: 0, width: 38, minWidth: 0, backgroundColor: 'transparent' }}
          onClick={handleResizeToDefaultWidth}
        >
          <DefaultWidthIcon
            sx={{ fontSize: '1.25rem', transform: 'rotate(90deg)' }}
          />
        </Button>
      </Tooltip>
    </Box>
  );
}
