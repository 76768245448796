import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function TikTokIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 32 32">
        <path
          fill="#161823"
          d="m18.16359,22.58032c-0.08352,2.18268 -1.92161,3.93438 -4.17492,3.93438c-0.51512,0 -1.00829,-0.09179 -1.46392,-0.25947c0.45563,0.16768 0.94895,0.25947 1.46408,0.25947c2.25331,0 4.0914,-1.75171 4.17508,-3.93422l0.00795,-19.49024l3.6431,0c0.35111,1.85289 1.47649,3.4428 3.03554,4.43614c0.00048,0.00064 0.00111,0.00127 0.00159,0.00191c1.08529,0.69123 2.37931,1.09452 3.76846,1.09452l0,1.08259c0,0 0,0 0.00016,0.00016l0,3.77975c-2.58039,0 -4.97147,-0.80673 -6.92315,-2.17583l0,9.88313c0,4.93584 -4.10874,8.95136 -9.15928,8.95136c-1.95152,0 -3.7613,-0.60151 -5.24892,-1.62237c-0.0008,-0.0008 -0.00159,-0.00175 -0.00255,-0.00255c-2.36053,-1.62078 -3.9075,-4.29997 -3.9075,-7.32739c0,-4.93568 4.10874,-8.95136 9.15928,-8.95136c0.41904,0 0.8298,0.03373 1.2342,0.08734l0,1.15211c-4.60748,0.10516 -8.385,3.54955 -8.88645,7.94847c0.50192,-4.39844 4.27913,-7.84236 8.88629,-7.94752l0,3.81268c-0.39072,-0.11947 -0.80371,-0.189 -1.23436,-0.189c-2.30645,0 -4.18288,1.83412 -4.18288,4.08822c0,1.56971 0.91125,2.93357 2.24265,3.61828c0,0.00016 0,0.00016 0,0.00016c0.58051,0.29861 1.23993,0.46963 1.94007,0.46963c2.25331,0 4.0914,-1.75171 4.17508,-3.93422l0.00795,-19.4904l4.97625,0c0,0.42158 0.04152,0.83362 0.11741,1.2342l-3.6431,0l-0.00811,19.49008z"
          clipRule="evenodd"
          fillRule="evenodd"
        />
      </svg>
    </SvgIcon>
  );
}
