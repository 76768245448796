import type { ButtonProps } from '@mui/material';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import {
  SNAPCHAT_ADS_REPORTING_INTEGRATION_ID,
  deleteIntegration,
  IntegrationActionsMenu,
  selectIntegrations,
  selectIsLoading,
} from '@/entities/datasources';
import { formatDate } from '@/shared/lib';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Button, Loader } from '@/shared/ui';
import type { SnapchatAdsReportingIntegration } from '../../model/types';
import { useURLSearchParamsBroadcaster } from '../../model/useURLSearchParamsBroadcaster';
import { BROADCAST_CHANNEL_NAME } from './model/constants';
import { useSnapchatAuthCode } from './model/useSnapchatAuthCode';
import { SnapchatIcon } from './ui/SnapchatIcon/SnapchatIcon';

const SnapchatButton = styled((props: ButtonProps) => {
  return <Button variant="outlined" color="secondary" {...props} />;
})(() => ({
  color: '#000000',
})) as typeof Button;

export function SnapchatAdsDatasource() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const integrationsLoading = useAppSelector(selectIsLoading);
  const integrations = useAppSelector(selectIntegrations);
  const snapchatAdsReportingIntegrations = useMemo(() => {
    return integrations.filter((integration) => {
      return (
        integration.sourceIntegrationId ===
        SNAPCHAT_ADS_REPORTING_INTEGRATION_ID
      );
    }) as SnapchatAdsReportingIntegration[];
  }, [integrations]);
  const { processing, openDataAccessDialog } = useSnapchatAuthCode();

  useURLSearchParamsBroadcaster({
    channelName: BROADCAST_CHANNEL_NAME,
    params: ['code'],
  });

  const handleDeleteIntegration = (id: string) => () => {
    return dispatch(deleteIntegration(id))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Integration successfully deleted.', {
          variant: 'success',
          autoHideDuration: 2000,
        });
      })
      .catch(() => {
        enqueueSnackbar('Unable to delete integration. Please, try again.', {
          variant: 'error',
          autoHideDuration: 4000,
        });
      });
  };

  return (
    <Box
      sx={{
        py: 3,
        px: 5,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Last update date</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {snapchatAdsReportingIntegrations.length === 0 && (
            <TableRow>
              <TableCell colSpan={3} align="center">
                No accounts added
              </TableCell>
            </TableRow>
          )}
          {snapchatAdsReportingIntegrations.map((integration) => {
            return (
              <TableRow key={integration.id} sx={{ position: 'relative' }}>
                <TableCell>{integration.integrationConfig.email}</TableCell>
                <TableCell>{formatDate(integration.updatedAt)}</TableCell>
                <TableCell>
                  <IntegrationActionsMenu
                    integrationName={integration.integrationConfig.email}
                    onDelete={handleDeleteIntegration(integration.id)}
                  />
                  {/* Moved to cell to avoid warning about <div> cannot be child of <tr> */}
                  <Loader active={integration.updating} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <SnapchatButton
        startIcon={<SnapchatIcon />}
        onClick={openDataAccessDialog}
      >
        Add account
      </SnapchatButton>
      <Loader active={processing || integrationsLoading} />
    </Box>
  );
}
