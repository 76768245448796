import type { OrganizationDTO } from '../api/types';
import type { Organization } from '../model/types';

export const mapOrganization = (dto: OrganizationDTO): Organization => {
  return {
    id: dto.id,
    name: dto.name,
    showPaymentOvedueBanner: dto.show_payment_overdue_banner,
    currency: dto.currency,
  };
};
