import ArrowIcon from '@mui/icons-material/ArrowForwardRounded';
import { Box, Breadcrumbs, Typography, styled } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import type { Datasource } from '@/entities/datasources';
import { DATASOURCE_TYPE_LABEL_MAP } from '@/entities/datasources';
import { HowToSetupButton } from '../HowToSetupButton/HowToSetupButton';
import { StatusIndicator } from '../StatusIndicator/StatusIndicator';

const Link = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
}));

interface Props {
  datasource: Datasource;
}

export function DatasourceHeader({ datasource }: Props) {
  return (
    <Box
      sx={{
        pt: 0,
        px: 5,
        pb: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        boxShadow: '0px 8px 10px 0px #0023400D',
      }}
    >
      <Breadcrumbs
        sx={{
          py: 0.75,
          fontSize: '0.875rem',
          fontWeight: 500,
          lineHeight: 1.43,
        }}
        separator={<ArrowIcon sx={{ width: 14, height: 20 }} />}
      >
        <Link to="..">Data sources</Link>
        <Typography
          sx={{
            fontSize: '0.875rem',
            fontWeight: 500,
            lineHeight: 1.43,
          }}
        >
          {datasource.name}
        </Typography>
      </Breadcrumbs>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 3,
          }}
        >
          <Box
            component="img"
            alt={`${datasource.name} logo`}
            src={datasource.iconUrl}
            sx={{
              width: 60,
              height: 60,
              borderRadius: 1,
              border: '1px solid #d9d9d9',
              objectFit: 'contain',
              backgroundColor: 'white',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <StatusIndicator status={datasource.status} />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                sx={{ fontSize: '1rem', fontWeight: 500, lineHeight: 1.17 }}
              >
                {datasource.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: '0.75rem',
                  lineHeight: 1.17,
                  color: 'text.secondary',
                }}
              >
                {DATASOURCE_TYPE_LABEL_MAP[datasource.type]}
              </Typography>
            </Box>
          </Box>
        </Box>
        {datasource.howToSetupLink && <HowToSetupButton />}
      </Box>
    </Box>
  );
}
