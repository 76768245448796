import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import type { DatasourceType } from '@/entities/datasources';
import {
  DatasourceCard,
  DatasourceStatus,
  selectIsLoading,
} from '@/entities/datasources';
import { useAppSelector } from '@/shared/model';
import { Loader } from '@/shared/ui';
import { useDatasources } from '../../model/useDatasources';

const STATUS_MAP: Record<DatasourceStatus, number> = {
  [DatasourceStatus.error]: 0,
  [DatasourceStatus.active]: 1,
  [DatasourceStatus.inactive]: 2,
};

interface Props {
  datasourceTypes: DatasourceType[];
  searchQuery: string;
}

export function Marketplace({ datasourceTypes, searchQuery }: Props) {
  const integrationsLoading = useAppSelector(selectIsLoading);
  const datasources = useDatasources();
  const filteredDatasources = useMemo(() => {
    const typesSet = new Set(datasourceTypes);
    const preparedQuery = searchQuery.toLowerCase();

    return datasources
      .filter((datasource) => {
        return (
          datasource.name.toLocaleLowerCase().includes(preparedQuery) &&
          (typesSet.size === 0 || typesSet.has(datasource.type))
        );
      })
      .sort((a, b) => {
        if (a.status !== b.status) {
          const aStatus = STATUS_MAP[a.status];
          const bStatus = STATUS_MAP[b.status];

          return aStatus - bStatus;
        }

        return a.name.localeCompare(b.name);
      });
  }, [searchQuery, datasourceTypes, datasources]);

  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box
        component="ul"
        sx={{
          m: 0,
          listStyleType: 'none',
          px: 5,
          pb: 5,
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, 210px)',
          gridTemplateRows: 'repeat(auto-fit, 200px)',
          gap: 4,
        }}
      >
        {filteredDatasources.length === 0 && (
          <Typography
            variant="body1"
            sx={{
              gridColumn: 1 / -1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            No data sources matching filter criteria
          </Typography>
        )}
        {filteredDatasources.map((datasource) => {
          return (
            <li key={datasource.id}>
              <Link
                style={{ textDecoration: 'none' }}
                to={`./${datasource.id}`}
              >
                <DatasourceCard datasource={datasource} />
              </Link>
            </li>
          );
        })}
      </Box>
      <Loader active={integrationsLoading} />
    </Box>
  );
}
