import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import {
  META_ADS_REPORTING_INTEGRATION_ID,
  deleteIntegration,
  IntegrationActionsMenu,
  selectIntegrations,
  selectIsLoading,
} from '@/entities/datasources';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Button, Loader } from '@/shared/ui';
import { updateMetaAdsReportingIntegrationAdAccountsActiveStatusRecord } from '../../model/actions';
import type {
  ActiveStatusRecord,
  MetaAdsReportingIntegration,
} from '../../model/types';
import { useURLSearchParamsBroadcaster } from '../../model/useURLSearchParamsBroadcaster';
import { AccountsActiveStatusList } from '../AccountsActiveStatusList/AccountsActiveStatusList';
import { BROADCAST_CHANNEL_NAME } from './model/constants';
import { useMetaOauthCode } from './model/useMetaOauthCode';
import { AccountInfoLabel } from './ui/AccountInfoLabel/AccountInfoLabel';
import { MetaIcon } from './ui/MetaIcon/MetaIcon';

const MetaButton = styled(Button)(() => ({
  backgroundColor: '#4267b2',

  '&:hover': {
    backgroundColor: '#36538f',
  },
})) as typeof Button;

export function MetaAdsDatasource() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const integrationsLoading = useAppSelector(selectIsLoading);
  const integrations = useAppSelector(selectIntegrations);
  const metaAdsReportingIntegrations = useMemo(() => {
    return integrations.filter((integration) => {
      return (
        integration.sourceIntegrationId === META_ADS_REPORTING_INTEGRATION_ID
      );
    }) as MetaAdsReportingIntegration[];
  }, [integrations]);
  const { processing, openDataAccessDialog } = useMetaOauthCode();

  useURLSearchParamsBroadcaster({
    channelName: BROADCAST_CHANNEL_NAME,
    params: ['code'],
  });

  const handleDeleteIntegration = (id: string) => () => {
    return dispatch(deleteIntegration(id))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Integration successfully deleted.', {
          variant: 'success',
          autoHideDuration: 2000,
        });
      })
      .catch(() => {
        enqueueSnackbar('Unable to delete integration. Please, try again.', {
          variant: 'error',
          autoHideDuration: 4000,
        });
      });
  };
  const handleUpdateAccountsActiveStatusRecord =
    (id: string) => (newActiveStatusRecord: ActiveStatusRecord) => {
      dispatch(
        updateMetaAdsReportingIntegrationAdAccountsActiveStatusRecord({
          id,
          activeStatusRecord: newActiveStatusRecord,
        }),
      ).catch(() => {
        enqueueSnackbar(
          'Unable to update ad accounts list. Please, try again.',
          {
            variant: 'error',
            autoHideDuration: 4000,
          },
        );
      });
    };

  return (
    <Box
      sx={{
        py: 3,
        px: 5,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Ad accounts list</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {metaAdsReportingIntegrations.length === 0 && (
            <TableRow>
              <TableCell colSpan={3} align="center">
                No accounts added
              </TableCell>
            </TableRow>
          )}
          {metaAdsReportingIntegrations.map((integration) => {
            const { id, name, adAccounts, adAccountsActiveStatusRecord } =
              integration.integrationConfig;

            return (
              <TableRow key={id} sx={{ position: 'relative' }}>
                <TableCell>{name}</TableCell>
                <TableCell>
                  <AccountsActiveStatusList
                    LabelComponent={AccountInfoLabel}
                    allAcounts={adAccounts}
                    value={adAccountsActiveStatusRecord}
                    onChange={handleUpdateAccountsActiveStatusRecord(
                      integration.id,
                    )}
                  />
                </TableCell>
                <TableCell>
                  <IntegrationActionsMenu
                    integrationName={name}
                    onDelete={handleDeleteIntegration(integration.id)}
                  />
                  {/* Moved to cell to avoid warning about <div> cannot be child of <tr> */}
                  <Loader active={integration.updating} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <MetaButton startIcon={<MetaIcon />} onClick={openDataAccessDialog}>
        Add account
      </MetaButton>
      <Loader active={processing || integrationsLoading} />
    </Box>
  );
}
