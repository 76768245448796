import type { Integration, IntegrationDTO } from '@/entities/datasources';
import { mapIntegration } from '@/entities/datasources';
import { baseApi, getErrorMessage } from '@/shared/api';
import type { ActiveStatusRecord } from '../model/types';

interface SubmitGooglePlayConsoleReportingIntegrationParams {
  name: string;
  cloudStorageURI: string;
  serviceCredentials: string;
}

export const submitGooglePlayConsoleReportingIntegration = async (
  params: SubmitGooglePlayConsoleReportingIntegrationParams,
) => {
  const { data, error } = await baseApi.functions.invoke<{
    integration: IntegrationDTO;
  }>('submitGooglePlayConsoleReportingIntegration', {
    body: {
      name: params.name,
      cloudStorageURI: params.cloudStorageURI,
      serviceCredentials: params.serviceCredentials,
    },
  });

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return mapIntegration(data.integration);
};

interface SubmitGoogleAnalyticsReportingIntegrationParams {
  bucket: string;
  subscriptionId: string;
}

export const submitGoogleAnalyticsReportingIntegration = async (
  params: SubmitGoogleAnalyticsReportingIntegrationParams,
) => {
  const { data, error } = await baseApi.functions.invoke<{
    integration: IntegrationDTO;
  }>('submitGoogleAnalyticsReportingIntegration', {
    body: {
      bucket: params.bucket,
      subscriptionId: params.subscriptionId,
    },
  });

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return mapIntegration(data.integration);
};

interface SubmitMintegralReportingIntegrationParams {
  accountName: string;
  apiKey: string;
  accessKey: string;
}

export const submitMintegralReportingIntegration = async (
  params: SubmitMintegralReportingIntegrationParams,
) => {
  const { data, error } = await baseApi.functions.invoke<{
    integration: IntegrationDTO;
  }>('submitMintegralReportingIntegration', {
    body: {
      accountName: params.accountName,
      apiKey: params.apiKey,
      accessKey: params.accessKey,
    },
  });

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return mapIntegration(data.integration);
};

interface SubmitAppsflyerReportingIntegrationParams {
  accountName: string;
  token: string;
}

export const submitAppsflyerReportingIntegration = async (
  params: SubmitAppsflyerReportingIntegrationParams,
) => {
  const { data, error } = await baseApi.functions.invoke<{
    integration: IntegrationDTO;
  }>('submitAppsflyerReportingIntegration', {
    body: {
      accountName: params.accountName,
      token: params.token,
    },
  });

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return mapIntegration(data.integration);
};

interface SubmitLiftoffReportingIntegrationParams {
  accountName: string;
  apiKey: string;
  apiSecret: string;
}

export const submitLiftoffReportingIntegration = async (
  params: SubmitLiftoffReportingIntegrationParams,
) => {
  const { data, error } = await baseApi.functions.invoke<{
    integration: IntegrationDTO;
  }>('submitLiftoffReportingIntegration', {
    body: {
      accountName: params.accountName,
      apiKey: params.apiKey,
      apiSecret: params.apiSecret,
    },
  });

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return mapIntegration(data.integration);
};

interface SubmitXAdsReportingIntegrationParams {
  apiKey: string;
  apiKeySecret: string;
  accessToken: string;
  accessTokenSecret: string;
}

export const submitXAdsReportingIntegration = async (
  params: SubmitXAdsReportingIntegrationParams,
) => {
  const { data, error } = await baseApi.functions.invoke<{
    integration: IntegrationDTO;
  }>('submitXAdsReportingIntegration', {
    body: {
      apiKey: params.apiKey,
      apiKeySecret: params.apiKeySecret,
      accessToken: params.accessToken,
      accessTokenSecret: params.accessTokenSecret,
    },
  });

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return mapIntegration(data.integration);
};

interface SubmitIronSourceReportingIntegrationParams {
  accountName: string;
  secretKey: string;
  refreshToken: string;
}

export const submitIronSourceIntegration = async (
  params: SubmitIronSourceReportingIntegrationParams,
) => {
  const { data, error } = await baseApi.functions.invoke<{
    integration: IntegrationDTO;
  }>('submitIronSourceReportingIntegration', {
    body: {
      accountName: params.accountName,
      secretKey: params.secretKey,
      refreshToken: params.refreshToken,
    },
  });

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return mapIntegration(data.integration);
};

interface SubmitMolocoAdsReportingIntegrationParams {
  accountName: string;
  adAccounts: string;
  apiKey: string;
}

export const submitMolocoAdsReportingIntegration = async (
  params: SubmitMolocoAdsReportingIntegrationParams,
) => {
  const { data, error } = await baseApi.functions.invoke<{
    integration: IntegrationDTO;
  }>('submitMolocoAdsReportingIntegration', {
    body: {
      accountName: params.accountName,
      adAccounts: params.adAccounts,
      apiKey: params.apiKey,
    },
  });

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return mapIntegration(data.integration);
};

interface SubmitUnityAdsReportingIntegrationParams {
  accountName: string;
  keyId: string;
  secretKey: string;
}

export const submitUnityAdsReportingIntegration = async (
  params: SubmitUnityAdsReportingIntegrationParams,
) => {
  const { data, error } = await baseApi.functions.invoke<{
    integration: IntegrationDTO;
  }>('submitUnityAdsReportingIntegration', {
    body: {
      accountName: params.accountName,
      keyId: params.keyId,
      secretKey: params.secretKey,
    },
  });

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return mapIntegration(data.integration);
};

interface SubmitPayPalReportingIntegrationParams {
  webhookId: string;
  clientId: string;
  clientSecret: string;
}

export const submitPayPalReportingIntegration = async (
  params: SubmitPayPalReportingIntegrationParams,
) => {
  const { data, error } = await baseApi.functions.invoke<{
    integration: IntegrationDTO;
  }>('submitPayPalReportingIntegration', {
    body: {
      webhookId: params.webhookId,
      clientId: params.clientId,
      clientSecret: params.clientSecret,
    },
  });

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return mapIntegration(data.integration);
};

interface SubmitStripeReportingIntegrationParams {
  accountId: string;
  secretKey: string;
  signingSecret: string;
}

export const submitStripeReportingIntegration = async (
  params: SubmitStripeReportingIntegrationParams,
) => {
  const { data, error } = await baseApi.functions.invoke<{
    integration: IntegrationDTO;
  }>('submitStripeReportingIntegration', {
    body: {
      accountId: params.accountId,
      secretKey: params.secretKey,
      signingSecret: params.signingSecret,
    },
  });

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return mapIntegration(data.integration);
};

interface SubmitAppStoreConnectReportingIntegrationParams {
  name: string;
  keyId: string;
  issuerId: string;
  vendorId: string;
  p8Key: string;
}

export const submitAppStoreConnectReportingIntegration = async (
  params: SubmitAppStoreConnectReportingIntegrationParams,
) => {
  const { data, error } = await baseApi.functions.invoke<{
    integration: IntegrationDTO;
  }>('submitAppStoreConnectReportingIntegration', {
    body: {
      name: params.name,
      keyId: params.keyId,
      issuerId: params.issuerId,
      vendorId: params.vendorId,
      p8Key: params.p8Key,
    },
  });

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return mapIntegration(data.integration);
};

interface SubmitAppLovinAdsReportingIntegrationParams {
  accountName: string;
  reportKey: string;
}

export const submitAppLovinAdsReportingIntegration = async (
  params: SubmitAppLovinAdsReportingIntegrationParams,
) => {
  const { data, error } = await baseApi.functions.invoke<{
    integration: IntegrationDTO;
  }>('submitAppLovinAdsReportingIntegration', {
    body: {
      accountName: params.accountName,
      reportKey: params.reportKey,
    },
  });

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return mapIntegration(data.integration);
};

interface SubmitAppleSearchAdsReportingIntegrationParams {
  campaignGroupId: string;
  teamId: string;
  keyId: string;
  clientId: string;
}

export const submitAppleSearchAdsReportingIntegration = async (
  params: SubmitAppleSearchAdsReportingIntegrationParams,
): Promise<Integration> => {
  const { data, error } = await baseApi.functions.invoke<{
    integration: IntegrationDTO;
  }>('submitAppleSearchAdsReportingIntegration', {
    body: {
      campaignGroupId: params.campaignGroupId,
      teamId: params.teamId,
      keyId: params.keyId,
      clientId: params.clientId,
    },
  });

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return mapIntegration(data.integration);
};

interface SubmitSnapchatAdsReportingIntegrationParams {
  code: string;
  clientId: string;
  redirectURI: string;
}

export const submitSnapchatAdsReportingIntegration = async (
  params: SubmitSnapchatAdsReportingIntegrationParams,
): Promise<Integration> => {
  const { data, error } = await baseApi.functions.invoke<{
    integration: IntegrationDTO;
  }>('submitSnapchatAdsReportingIntegration', {
    body: {
      code: params.code,
      clientId: params.clientId,
      redirectURI: params.redirectURI,
    },
  });

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return mapIntegration(data.integration);
};

interface SubmitTikTokForBusinessReportingIntegrationParams {
  authCode: string;
  code: string;
  appId: string;
}

export const submitTikTokForBusinessReportingIntegration = async (
  params: SubmitTikTokForBusinessReportingIntegrationParams,
): Promise<Integration> => {
  const { data, error } = await baseApi.functions.invoke<{
    integration: IntegrationDTO;
  }>('submitTikTokForBusinessReportingIntegration', {
    body: {
      code: params.code,
      authCode: params.authCode,
      appId: params.appId,
    },
  });

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return mapIntegration(data.integration);
};

interface SubmitGoogleAdsReportingIntegrationParams {
  clientId: string;
  code: string;
  redirectURI: string;
}

export const submitGoogleAdsReportingIntegration = async (
  params: SubmitGoogleAdsReportingIntegrationParams,
): Promise<Integration> => {
  const { data, error } = await baseApi.functions.invoke<{
    integration: IntegrationDTO;
  }>('submitGoogleAdsReportingIntegration', {
    body: {
      code: params.code,
      clientId: params.clientId,
      redirectURI: params.redirectURI,
    },
  });

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return mapIntegration(data.integration);
};

interface SubmitMetaAdsReportingIntegrationParams {
  code: string;
  clientId: string;
  redirectURI: string;
}

export const submitMetaAdsReportingIntegration = async (
  params: SubmitMetaAdsReportingIntegrationParams,
): Promise<Integration> => {
  const { data, error } = await baseApi.functions.invoke<{
    integration: IntegrationDTO;
  }>('submitMetaAdsReportingIntegration', {
    body: {
      code: params.code,
      clientId: params.clientId,
      redirectURI: params.redirectURI,
    },
  });

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return mapIntegration(data.integration);
};

interface UpdateMetaAdsReportingIntegrationAdAccountsListParams {
  id: string;
  activeStatusRecord: ActiveStatusRecord;
}

export const updateMetaAdsReportingIntegrationAdAccountsActiveStatusRecord =
  async (params: UpdateMetaAdsReportingIntegrationAdAccountsListParams) => {
    const { data, error } = await baseApi.functions.invoke<{
      integration: IntegrationDTO;
    }>('updateMetaAdsReportingIntegrationAdAccountsActiveStatusRecord', {
      body: {
        integrationId: params.id,
        adAccountsActiveStatusRecord: params.activeStatusRecord,
      },
    });

    if (!data) {
      const errorMessage = getErrorMessage(error);

      throw new Error(errorMessage);
    }

    return mapIntegration(data.integration);
  };

interface UpdateGoogleAdsReportingIntegrationAccountListActiveRecordParams {
  id: string;
  activeStatusRecord: ActiveStatusRecord;
}

export const updateGoogleAdsReportingIntegrationAccountsActiveStatusRecord =
  async (
    params: UpdateGoogleAdsReportingIntegrationAccountListActiveRecordParams,
  ) => {
    const { data, error } = await baseApi.functions.invoke<{
      integration: IntegrationDTO;
    }>('updateGoogleAdsReportingIntegrationAccountsActiveStatusRecord', {
      body: {
        integrationId: params.id,
        accountListActiveStatusRecord: params.activeStatusRecord,
      },
    });

    if (!data) {
      const errorMessage = getErrorMessage(error);

      throw new Error(errorMessage);
    }

    return mapIntegration(data.integration);
  };
